<template>
  <v-scale-transition>
    <panel
      :breadcrumbs="breadcrumbs"
      :title="title"
    >
      <v-toolbar
        flat
        prominent
      >
        <v-toolbar-title>Set to</v-toolbar-title>
        <v-divider
          class="mx-2"
          inset
          vertical
        />
        <v-autocomplete
          v-model="dealer"
          :hint="dealerInfo"
          :loading="dealerLoading"
          :items="dealerList"
          :search-input.sync="search"
          item-text="dealer"
          label="Start typing and select dealerID"
          prepend-icon="mdi-account-tie"
          persistent-hint
          hide-no-data
          return-object
        >
          <template #item="data">
            <template v-if="typeof data.item !== 'object'">
              <v-list-tile-content v-text="data.dealer" />
            </template>
            <template v-else>
              <v-list-tile-content>
                <v-list-tile-title v-html="data.item.dealer" />
                <v-list-tile-sub-title>
                  {{ data.item.msisdn + ': ' + data.item.name }}
                </v-list-tile-sub-title>
              </v-list-tile-content>
            </template>
          </template>
        </v-autocomplete>
        <v-spacer />
        <v-dialog
          v-model="dialog"
          max-width="500px"
        >
          <template #activator="{ on }">
            <v-btn
              color="primary"
              dark
              class="mb-2"
              v-on="on"
            >
              Add MSISDN
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">Add MSISDN</span>
            </v-card-title>

            <v-card-text>
              <v-container grid-list-md>
                <v-layout wrap>
                  <v-flex xs12>
                    <v-text-field
                      v-model="addedItem.msisdn"
                      label="Msisdn"
                    />
                  </v-flex>
                </v-layout>
              </v-container>
            </v-card-text>

            <v-alert
              :value="alert"
              type="error"
            >
              {{ alertMessage }}
            </v-alert>
            <v-card-actions>
              <v-spacer />
              <v-btn
                color="blue darken-1"
                flat
                @click="close"
              >
                Cancel
              </v-btn>
              <v-btn
                color="blue darken-1"
                flat
                @click="save"
              >
                Add Msisdn
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
      <v-data-table
        :headers="headers"
        :items="msisdns"
        class="elevation-1"
      >
        <template #items="props">
          <td class="text-xs-left">
            {{ props.item.msisdn }}
          </td>
          <td class="text-xs-left">
            {{ props.item.name }}
          </td>
          <td class="justify-center layout px-0">
            <v-icon
              small
              @click="deleteItem(props.item)"
            >
              delete
            </v-icon>
          </td>
        </template>
      </v-data-table>
      <v-dialog
        v-model="executeDialog"
        max-width="500px"
      >
        <template #activator="{ on }">
          <v-btn
            :disabled="disabled"
            color="primary"
            dark
            class="mb-2"
            v-on="on"
          >
            Execute Set Introducer
          </v-btn>
        </template>
        <v-card>
          <v-card-title>
            <span class="headline">Execute Set Introducer</span>
          </v-card-title>

          <v-alert
            :value="true"
            :type="executeAlertType"
            v-html="executeAlertMessage"
          />

          <v-card-actions>
            <v-spacer />
            <v-btn
              :disabled="loading"
              color="blue darken-1"
              flat
              @click="executeDialog = false"
            >
              {{ executeAlertType === 'info' ? 'Cancel' : 'Close' }}
            </v-btn>
            <v-btn
              v-show="executeAlertType === 'info'"
              :loading="loading"
              :disabled="loading"
              color="blue darken-1"
              flat
              @click="executeTransferHierarchy"
            >
              Execute
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </panel>
  </v-scale-transition>
</template>

<script>
import { createGetParams, createPostParams } from '@/rest'

const pageTitle = 'Set Introducer'

export default {
  components: {
    Panel: () => import(/* webpackChunkName: "panel" */ '@/components/Panel'),
  },
  data: () => ({
    dialog: false,
    title: pageTitle,
    alert: false,
    alertMessage: null,
    dealer: null,
    dealerList: [],
    dealerInfo: 'No dealer selected.',
    search: null,
    searchId: null,
    source: null,
    dealerLoading: false,
    loading: false,
    executeDialog: false,
    executeAlertType: 'info',
    executeAlertMessage: null,
    breadcrumbs: [
      {
        text: 'DMT Kits', disabled: false, to: '/support/dmt_kits',
      },
      {
        text: pageTitle, disabled: true,
      },
    ],
    headers: [
      {
        text: 'MSISDN',
        align: 'left',
        sortable: true,
        value: 'msisdn',
      },
      {
        text: 'Name',
        align: 'left',
        sortable: true,
        value: 'name',
      },
      {
        text: 'Actions',
        align: 'center',
        value: 'msisdn',
        sortable: false,
      },
    ],
    msisdns: [],
    addedItem: {
      msisdn: null,
      name: null,
    },
    defaultItem: {
      msisdn: null,
      name: null,
    },
  }),

  computed: {
    disabled: function () {
      return this.msisdns.length === 0 || !this.dealer
    },
  },

  watch: {
    executeDialog (val) {
      if (val) {
        this.executeAlertType = 'info'
        this.executeAlertMessage = '<div>Click <strong>EXECUTE</strong> only when you are sure that the new introducer and MSISDN list are correct.</div>'
      }
    },

    dialog (val) {
      if (val) {
        this.alert = false
        this.alertMessage = null
      } else {
        this.close()
      }
    },

    dealer: function (dealer) {
      if (dealer) {
        this.dealerInfo = dealer.msisdn + ': ' + dealer.name
      } else {
        this.dealerInfo = 'No dealer selected.'
      }
    },

    search: function (val) {
      clearTimeout(this.searchId)
      this.cancelRequest()
      if (val) {
        this.searchId = setTimeout(() => {
          this.listDealers()
        }, 1000)
      }
    },
  },

  methods: {
    async executeTransferHierarchy () {
      const params = createPostParams({
        action: 'setIntroducer',
        payload: {
          dealer: this.dealer.dealer,
          subscribers: this.msisdns,
        },
      })

      try {
        this.loading = true
        await this.$rest.post('postAction.php', params)
        this.$nextTick(() => {
          this.executeAlertType = 'success'
          this.executeAlertMessage = 'Your request to transfer hierarchy to a new introducer was successful.'
          this.msisdns = []
        })
      } catch (e) {
        this.$nextTick(() => {
          this.executeAlertType = 'error'
          this.executeAlertMessage = e.message + ': ' + e.response.data
        })
      } finally {
        this.loading = false
      }
    },

    listDealers: async function () {
      this.dealerLoading = true

      const param = createGetParams({ search: this.search })

      try {
        this.source = this.$rest.CancelToken.source()
        Object.assign(param, { cancelToken: this.source.token })
        const response = await this.$rest.get('listDealers.php', param)
        this.dealerList = response.data
      } catch (error) {
        if (!this.$rest.isCancel(error)) {
          this.hint = error.message
        }
        this.dealerList = []
      } finally {
        this.dealerLoading = false
      }
    },

    cancelRequest: function () {
      this.dealerLoading = false
      if (this.source) {
        this.source.cancel('Forced cancellation.')
        this.source = null
      }
    },

    deleteItem (item) {
      const index = this.msisdns.indexOf(item)
      this.msisdns.splice(index, 1)
    },

    close () {
      this.dialog = false
      setTimeout(() => {
        this.addedItem = Object.assign({}, this.defaultItem)
      }, 300)
    },

    async save () {
      const param = createGetParams({ msisdn: this.addedItem.msisdn })

      try {
        const response = await this.$rest.get('getSubscriberWIntroducer.php', param)
        this.addedItem.name = response.data.name
        this.addedItem.introducer = response.data.introducer
        this.msisdns.push(this.addedItem)
        this.close()
      } catch (error) {
        console.log(error.response)
        this.alertMessage = error.message
        if (error.response) {
          this.alertMessage += ' - ' + error.response.data
        }
        this.alert = true
      }
    },
  },
}
</script>
